import { orderBy } from "lodash";

export const getAreaGroupList = (state, response, hasError = false, message = '') => {
  const { data } = response;
  return {
    ...state,
    ...{
      loading: false,
      hasError: hasError,
      messageText: message,
      areaGroupList: data,
      total: data.total,
      newAreaGroupAdded: false,
    },
  };
};

export const getAreaGroupDetailsById = (state, response, hasError = false, message = '') => {
  const { data } = response;
  return {
    ...state,
    ...{
      loading: false,
      hasError: hasError,
      messageText: message,
      areaGroupDetailsById: data,
      // areaGroupDetailsByIdAdded: true,
    },
  };
};

export const getLocationsByStateIds = (state, response, hasError = false, message = '') => {
  const { data } = response;
  return {
    ...state,
    ...{
      loading: false,
      hasError: hasError,
      messageText: message,
      stateLocationsList: data,
      total: data.total,
    },
  };
};

export const emptyStatesLocations = (state) => {
  return {
    ...state,
    ...{
      stateLocationsList: [],
      areaGroupDetails: [],
      total: 0,
    },
  };
};

export const emptyAllLocations = (state) => {
  return {
    ...state,
    ...{
      areaGroupDetailsById: [],
      stateLocationsList: [],
      areaGroupDetails: [],
      total: 0,
    },
  };
};


export const getAreaGroupDetails = (state, response, hasError = false, message = '') => {
  const { data } = response;
  return {
    ...state,
    ...{
      loading: false,
      hasError: hasError,
      messageText: message,
      areaGroupDetails:  data,
      newAreaGroupAdded: false,
      areaGroupUpdated: false,
    },
  };
};

export const addAreaGroupDetails = (state, response, hasError = false, message = '') => {
  const { data } = response.response;
  return {
    ...state,
    ...{
      hasError: data.hasError,
      messageText: data.message || "Area Group Details updated",
      areaGroupDetails:  data,
      areaGroupUpdated: true,
    },
  };
};

export const updateAreaGroupDetails = (state, response) => {
  const { data } = response.response;
  return {
    ...state,
    ...{
      hasError: data.hasError,
      messageText: data.message,
      areaGroupDetails: data,
      submitActinType: response.submitActinType,
      newAreaGroupAdded: false,
      areaGroupUpdated: true,
    },
  };
};

export const getStatesList = (state, response, hasError = false, message = '') => {
  const { data } = response;
  return {
    ...state,
    ...{
      hasError: hasError,
      messageText: message,
      statesList:  data,
    },
  };
};

export const sortBy = (state, sortValue) => ({
  ...state,
  ...{
      areaGroupList: [...orderBy(state.areaGroupList, item => item.rowState.areaGroupData.name, sortValue)],
      sortType: sortValue,
  },
});

export const setDisplayState = (state, rowState, header, enable) => ({
  ...state,
  ...{
    lastSelected: {
      id: rowState.id,
      headerKey: header.key,
      enable: enable,
    },
  },
});

export const setHeaderWidth = (state, ind, width) => {
  const header = state.headers[ind];
  header.width = width;
  return {
    ...state,
    ...{
      headers: [...state.headers.slice(0, ind), ...[header], ...state.headers.slice(ind + 1)],
    },
  };
};

export const setError = (state, errorMessage) => ({
  ...state,
  ...{
    hasError: true,
    messageText: errorMessage ? errorMessage : 'Something went wrong, please try again',
  },
});

export const resetError = state => ({
  ...state,
  ...{
    hasError: false,
    message: '',
    loading: false,
    areaGroupUpdated: false,
    newFactoryAdded: false,
  },
});

export const setInLoading = state => ({
  ...state,
  ...{
    loading: true,
  },
});


