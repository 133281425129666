import axios from 'axios';
import store from '../..';
import * as actionTypes from './areaGroupDetailsActionTypes';

const buildUrl = (resource, id) => (id ? `/api/${resource}/${id}` : `/api/${resource}`);

const axiosConfig = () => ({
  headers: {
    Authorization: 'Bearer ' + store.getState().auth.userToken,
  },
});

export const getAreaGroupList = (resource, url, name) => dispatch => {
  const data = {
    params: {
      filterData: { name: name },
    },
    headers: axiosConfig().headers,
  };
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_DETAILS_LIST_LOADING) });
  axios
    .get(buildUrl(resource, url), data, axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_DETAILS_LIST), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const getAreaGroupDetails = (resource, id) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_DETAILS_DETAILS_LOADING) });
  axios
    .get(buildUrl(resource, id), axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_DETAILS_DETAILS), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const getLocationsByState = (resource, url, stateIds) => dispatch => {
  const data = {
    params: {
      filterData: { stateId: stateIds.toString() },
    },
    headers: axiosConfig().headers,
  };
  // dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_LOCATIONS_LIST_BY_STATE_IDS_LOADING) });
  axios
    .get(buildUrl(resource, url), data, axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_LOCATIONS_LIST_BY_STATE_IDS), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const addAreaGroupDetails = (resource, url, values, submitActinType) => dispatch => {
  // dispatch({ type: actionTypes.getActionType(resource, actionTypes.ADD_AREA_GROUP_DETAILS_LOADING) });
  console.log("values", values)
  const data = {
      areaGroupId: values.areaGroupId,
      locationIds: [40299, 39618] // values.locationIds, 
  };
  axios
    .post(buildUrl(resource, url), data, axiosConfig())
    .then(response => {
      if (response.data.hasError) {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR), data: response.data.message });
      } else {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.ADD_AREA_GROUP_DETAILS), data: { response, submitActinType } });
      }
    })
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const updateAreaGroup = (resource, areaGroupNewData, submitActinType) => dispatch => {
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.UPDATE_AREA_GROUP_DETAILS_LOADING) });
  axios
    .put(buildUrl(resource), areaGroupNewData, axiosConfig())
    .then(response => {
      if (response.data.hasError) {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR), data: response.data.message });
      } else {
        dispatch({ type: actionTypes.getActionType(resource, actionTypes.UPDATE_AREA_GROUP_DETAILS), data: { response, submitActinType } });
        dispatch(reloadAfterUpdate(resource));
      }
    })
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const deleteAreaGroup = (resource, id) => dispatch => {
  const filterData = {
    params: { id: id },
    headers: axiosConfig().headers,
  };
  dispatch({ type: actionTypes.getActionType(resource, actionTypes.DELETE_AREA_GROUP_DETAILS_LOADING) });
  axios
    .delete(buildUrl(resource), filterData, axiosConfig())
    .then(response => {
      if (response) {
        setTimeout(window.location.replace(`/areaGroup`), 1000);
      }
    })
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const emptyAreaGroupDetails = resource => dispatch => {
  dispatch({
    type: actionTypes.getActionType(resource, actionTypes.EMPTY_AREA_GROUP_DETAILS_DETAILS),
  });
};

export const getStatesListByCountryId = (resource, id) => dispatch => {
  axios
    .get(buildUrl(resource, id), axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_STATES_LIST), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};

export const sortBy = (resource, sortValue) => dispatch => {
  dispatch({
    type: actionTypes.getActionType(resource, actionTypes.SORT_AREA_GROUP_DETAILS),
    data: sortValue,
  });
};

export const setHeaderWidth = (resource, ind, width) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_HEADER_WIDTH_AREA_GROUP_DETAILS),
  data: { ind, width },
});

export const setItemDisplayState = (resource, rowState, header, enable) => ({
  type: actionTypes.getActionType(resource, actionTypes.SET_DISPLAY_STATE),
  data: {
    rowState,
    header,
    enable,
  },
});

export const resetError = resource => ({ type: actionTypes.getActionType(resource, actionTypes.RESET_ERROR) });

// const errorAction = (resource, reload, errorText) => dispatch => {
//   if (reload) {
//       axios.get(buildUrl(resource),
//           axiosConfig()
//       )
//           .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.RELOAD_ON_ERROR),
//             data: {
//               response, errorText,
//             }}))
//             .catch((err) => {
//                 console.error(err);
//                 console.log(err.response);
//                 dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
//           });
//   }
// };

const reloadAfterUpdate = resource => dispatch => {
  axios
    .get(buildUrl(resource), axiosConfig())
    .then(response => dispatch({ type: actionTypes.getActionType(resource, actionTypes.GET_AREA_GROUP_DETAILS_LIST), data: response }))
    .catch(err => {
      console.error(err);
      console.log(err.response);
      dispatch({ type: actionTypes.getActionType(resource, actionTypes.ERROR) });
    });
};
