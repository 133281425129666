import * as Yup from 'yup';

export const getCSVData = areaGroup => {
  let CSVData = [];
  for (let i = 0; i < areaGroup?.length; i++) {
      let object = {
          Name: areaGroup[i]?.rowState.areaGroupData.name,
          State: areaGroup[i]?.rowState.areaGroupData.states,
      }
      CSVData.push(object);
  };
  return CSVData;
}

export const validationSchema = Yup.object().shape({
  areaGroup: Yup.string().required('Area Group is required'),
});
