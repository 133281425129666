import { useEffect, useState } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import store from '../../../store';

export const useClientHubNewRequest = (onNotificationReceive, event) => {
    const [connection, setConnection] = useState(null);
    const pathname = window.location.pathname;
    const needSocket =  pathname === '/changerequest/customerorderadmin' || pathname === '/changerequest/revision';

    useEffect(() => {
        const token = store.getState().auth.userToken;
        if (!connection && needSocket) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(`/NewRequestNotificationHub`, {
                    accessTokenFactory: () => token
                })
                .configureLogging(LogLevel.Information)
                .withAutomaticReconnect()
                .build();
            setConnection(newConnection);
        }
        return () => {
            if (connection) {
                connection.stop();
            }
        };
    }, [connection, needSocket]);

    useEffect(() => {
        let isMounted = true;
        const fetchConnection = () => {
            if (isMounted && connection) {
                if (connection.connectionState === 'Disconnected') {
                    connection.start()
                        .then(() => {
                            console.log('Client HUB for NewRequest Connected!');
                            connection.on(event, message => {
                                onNotificationReceive(message);
                            });
                        })
                        .catch(e => console.error('Client HUB Connection failed: ', e));
                }
            }
        }
        needSocket && fetchConnection();
        return () => {
            isMounted = false;
        };
    }, [connection, onNotificationReceive, event, needSocket]);

    return null;
};
