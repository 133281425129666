import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { DownOutlined } from '@ant-design/icons';
import { message } from 'antd';
import Select from 'react-select';
import Geocode from "react-geocode";
import { GoogleMap, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api';
import { getAreaGroupList, getStatesListByCountryId, getLocationsByState, addAreaGroupDetails, resetError } from '../../../../store/actions/areaDetails/areaGroupDetails';
import { AreaGroupDetailsSelectors } from '../../../../store/selectors/areaGroupDetailsSelectors';
import { AuthSelectors } from '../../../../store/selectors/authSelectors';
import { customStyles, mapContainerStyle } from './customStyles';
import Loading from '../../screen/loading';
import { validationSchema } from './helper';
import './index.css'

const AreaGroupDetails = ({ location }) => {
  const dispatch = useDispatch();
  const resource = 'areaGroup';
  const id = location.pathname.split('/')[3];
  const { areaGroupDetails, areaGroupList, newAreaGroupAdded, areaGroupUpdated, stateLocationsList, messageText, hasError, statesList, loading } = AreaGroupDetailsSelectors();
  
  const defaultPosition = { lat: 39.8097343, lng: -98.5556199 };
  const libraries = ['places'];
  const { googleApiKey } = AuthSelectors();
  Geocode.setApiKey(googleApiKey);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${googleApiKey}&loading=async`,
    libraries,
  });

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [states, setStates] = useState('');
  const [infoWindowState, setInfoWindowState] = useState(false);

  useEffect(() => {
    dispatch(getAreaGroupList(resource, 'areagroupvaluelist'));
    // dispatch(getAreaGroupList(resource, 'areagroupvaluelist'));
    dispatch(getStatesListByCountryId('Location/statevaluelist', 234));
  }, [resource, id, dispatch]);

  useEffect(() => {
    if (states && states.length) {
      dispatch(getLocationsByState('location', 'locationlistbystates', states));
    }
  }, [resource, id, dispatch, states]);

  const areaGroupsOptions = areaGroupList?.map(group => ({
      value: group.value,
      label: group.text,
  }));

  const statesOptions = statesList && statesList?.length && statesList?.map(state => ({
    value: state.value,
    label: state.text,
  }));

  useEffect(() => {
    if (hasError) {
      message.error(messageText);
      dispatch(resetError(resource));
    }
  }, [dispatch, hasError, messageText, resource]);

  useEffect(() => {
    if (areaGroupUpdated && messageText) {
      message.success(messageText);
      dispatch(resetError(resource));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaGroupUpdated]);

  useEffect(() => {
    if (newAreaGroupAdded && messageText) {
      message.success(messageText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAreaGroupAdded]);

  const getTextFromValues = useCallback((value, list) => {
    const data = list?.length ? list?.filter(item => item.value === value)[0]?.label : [];
    return data;
  }, []);

  const handleSubmitSave = (values) => {
    const updatedAreaGroup = {
      areaGroupId: values.areaGroup,
      locationIds: values.states,
    };
    dispatch(addAreaGroupDetails("LocationGroup", "updateAreagrouplocations", updatedAreaGroup ))
    setButtonDisabled(false);
  };

  const handleSetFieldValue = (fieldName, value, setFieldValue, setFieldTouched) => {
    if (fieldName === 'areaGroup') {
      setFieldValue(fieldName, value.value);
    } else if (fieldName === 'states') {
      if (value.length === 0) {
        setStates([]);
        dispatch(getLocationsByState('location', 'locationlistbystates', states));
      }
      const statesList = value.map(state => state.value);
      setFieldValue(fieldName, statesList);
      setStates(statesList);
    }
    setTimeout(() => setFieldTouched(fieldName, true));
  };

  const handleCancel = setFieldValue => {
    setFieldValue('areaGroup', '');
    setFieldValue('states', []);
  }

  const infoWindowCloseHandler = () => {
      setInfoWindowState(false);
  };

  const infoWindowOpenHandler = (e) => {
      setInfoWindowState(true);
  };

  useEffect(() => {
      setInfoWindowState(false);
  }, []);

  return (
    <>
      {loading ? (
        <div style={{ marginTop: '18% ' }}>
          <Loading />
        </div>
      ) : (
        <div>
          <Formik
            initialValues={{
              areaGroup: areaGroupDetails?.areaGroup,
              states: areaGroupDetails?.states,
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { resetForm, setSubmitting, setFieldValue, errors, touched }) => {
              setButtonDisabled(true);
              setTimeout(() => {
                setSubmitting(false);
                setButtonDisabled(false);
                handleSubmitSave(values);
              }, 400);
            }}>
            {({ isSubmitting, isValid, values, setFieldValue, setFieldTouched, errors, touched, handleSubmit }) => {
              return (
                <Form>
                  <div className='area-group_formik_container'>
                    <div className='form__area-group'>
                      <div className='formik_form_area-group modal_formik_one_column'>
                        <div className='area-group__select'>
                          <div className='area-group__select__label'>Choose the Area Group</div>
                          <Select
                            options={areaGroupsOptions}
                            name='areaGroup'
                            value={{ label: getTextFromValues(values.areaGroup, areaGroupsOptions) }}
                            onChange={option => handleSetFieldValue('areaGroup', option, setFieldValue, setFieldTouched)}
                            styles={customStyles}
                            isSearchable={false}
                            components={{ DropdownIndicator: () => <DownOutlined />, IndicatorSeparator: () => null }}
                          />
                          {errors && <div className='factoring-error-msg'>{errors['areaGroup']}</div>}
                        </div>
                        {statesOptions && statesOptions?.length ? values.areaGroup && <div className='area-group__select'>
                          <div className='area-group__select__label'>Choose the Location</div>
                          <Select
                            isMulti
                            className="basic-multi-select"
                            classNamePrefix="select"
                            options={statesOptions}
                            name='states'
                            onChange={option => handleSetFieldValue('states', option, setFieldValue, setFieldTouched)}
                          />
                        </div> : null}
                      </div>
                    </div>

                    {isLoaded ? (
                      <GoogleMap
                          map='map'
                          mapContainerClassName='map'
                          mapContainerStyle={mapContainerStyle}
                          center={defaultPosition}
                          zoom={4}>
                          {/* {
                            stateLocationsList && stateLocationsList.data && stateLocationsList?.data?.map(location => {
                                  return (
                                      <Marker
                                          position={{ lat: location.lat, lng: location.lng }}
                                          onClick={infoWindowOpenHandler}
                                          key={location.locationName}
                                          // icon={getIcon(field.isTstWarehouse)}
                                          icon={{
                                            path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z",
                                            fillColor: "red",
                                            fillOpacity: 1,
                                            scale: 2,
                                            strokeColor: "white",
                                            strokeWeight: 1,
                                          }}>
                                          {
                                              infoWindowState &&
                                              <InfoWindow onCloseClick={infoWindowCloseHandler}
                                                  position={{ lat: location.lat, lng: location.lng }}
                                                  options={{ pixelOffset: { width: -1, height: -45 } }}>
                                                  <div>
                                                      <span>{location.locationName}</span>
                                                  </div>
                                              </InfoWindow>
                                          }
                                      </Marker>
                                  )
                              })
                          } */}
                      </GoogleMap>
                    ) : <></>}

                    <div className='area-group__buttons-container'>
                      <button
                        className='area-group__button'
                        type='submit'
                        disabled={!isValid || isSubmitting || buttonDisabled}
                        onClick={handleSubmit}>
                        Save Group
                      </button>
                      <button className='area-group__button' type='submit' onClick={() => handleCancel(setFieldValue)}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

export default withRouter(AreaGroupDetails);
