import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { DownOutlined } from '@ant-design/icons';
import { Checkbox, message } from 'antd';
import Select from 'react-select';
import Geocode from "react-geocode";
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { getAreaGroupList, getAreaGroupDetailsById, getStatesListByCountryId, getLocationsByState, addAreaGroupDetails, resetError, emptyStatesLocations, emptyAllLocations } from '../../../../store/actions/areaDetails/areaGroupDetails';
import { AreaGroupDetailsSelectors } from '../../../../store/selectors/areaGroupDetailsSelectors';
import { AuthSelectors } from '../../../../store/selectors/authSelectors';
import { customStyles, mapContainerStyle } from './customStyles';
import Loading from '../../screen/loading';
import { validationSchema, USStatesCenterPositions } from './helper';
import './index.css'

const AreaGroupDetails = ({ location }) => {
  const dispatch = useDispatch();
  const resource = 'areaGroup';
  const id = location.pathname.split('/')[3];
  const { areaGroupDetails, areaGroupList, statesList, stateLocationsList, areaGroupDetailsById, areaGroupUpdated, messageText, hasError, loading } = AreaGroupDetailsSelectors();
  
  const defaultPosition = { lat: 39.8097343, lng: -98.5556199 };
  const libraries = ['places'];
  const { googleApiKey } = AuthSelectors();
  Geocode.setApiKey(googleApiKey);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${googleApiKey}&loading=async`,
    libraries,
  });

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [states, setStates] = useState('');
  const [zoom, setZoom] = useState(4);
  const [positionCenter, setPositionCenter] = useState(defaultPosition);
  const [currentSelectedLocations, setCurrentSelectedLocations] = useState([]);
  const [currentAreaGroupLocations, setCurrentAreaGroupLocations] = useState([]);

  useEffect(() => {
    dispatch(getAreaGroupList(resource, 'areagroupvaluelist')); // areaGroups list
    dispatch(getStatesListByCountryId('/Location/statevaluelist', 234)); // US states list
  }, [resource, id, dispatch]);

    useEffect(() => {
      setCurrentAreaGroupLocations(areaGroupDetailsById?.data)
  }, [areaGroupDetailsById]);

  useEffect(() => {
    return () => dispatch(emptyAllLocations(resource)); // reset all redux when unmounting
  }, [dispatch]);

  useEffect(() => {
    if (states && states.length) {
      dispatch(getLocationsByState('location', 'locationlistbystates', states));
    }
  }, [resource, id, dispatch, states]);

  const areaGroupsOptions = areaGroupList?.map(group => ({
      value: group.value,
      label: group.text,
  }));

  const statesOptions = statesList && statesList?.length && statesList?.map(state => ({
    value: state.value,
    label: state.text,
  }));

  useEffect(() => {
    if (hasError) {
      message.error(messageText);
      dispatch(resetError(resource));
    }
  }, [dispatch, hasError, messageText, resource]);

  useEffect(() => {
    if (areaGroupUpdated && messageText) {
      message.success(messageText);
      dispatch(resetError(resource));
    }
  }, [areaGroupUpdated, dispatch, messageText]);

  const getTextFromValues = useCallback((value, list) => {
    const data = list?.length ? list?.filter(item => item.value === value)[0]?.label : [];
    return data;
  }, []);

  const handleSubmitSave = (values) => {
    const updatedAreaGroup = {
      areaGroupId: values.areaGroup,
      states: values.states,
      locationIds: values.locations,
    };
    dispatch(addAreaGroupDetails("LocationGroup", "updateAreagrouplocations", updatedAreaGroup ))
    setButtonDisabled(false);
  };

  const handleSetFieldValue = (fieldName, value, setFieldValue, setFieldTouched) => {
    if (fieldName === 'areaGroup') {
      setFieldValue(fieldName, value.value);
      dispatch(getAreaGroupDetailsById('LocationGroup', 'locationgrouplist', Number(value.value)));
      setPositionCenter(defaultPosition);
      setZoom(4);
    } else if (fieldName === 'states') {
      if (value.length === 0) {
        setStates([]);
        setZoom(4);
        setPositionCenter(defaultPosition);
        dispatch(emptyAllLocations(resource));
        setFieldValue('areaGroup', '');
        setFieldValue('states', []);
        setFieldValue('locations', []);
        setCurrentSelectedLocations([])
      } else if (value.length > 1) {
        setPositionCenter(defaultPosition);
        setZoom(4);
      } else {
        const stateShortName = value?.[0]?.label;
        setPositionCenter(USStatesCenterPositions[stateShortName]);
        setZoom(6);
      }
      const statesList = value?.length && value?.map(state => state.value);
      setFieldValue(fieldName, statesList);
      setStates(statesList);
    } else if (fieldName === 'locations') {
      console.log('value', value)
      if (value.latLng) { // if selected from Map
        const checkedOnRedLocations = currentAreaGroupLocations.filter(loc => loc.lat === value.latLng.lat() && loc.lng === value.latLng.lng()).length > 0;
        if (checkedOnRedLocations) {
          message.warning('You can remove already existing location only from Checkbox');
          return
        }
        const selectedMarker = stateLocationsList?.data?.length && stateLocationsList?.data.filter(loc => loc.lat === value.latLng.lat() && loc.lng === value.latLng.lng())[0].id;
        const selectedLocation = stateLocationsList?.data?.length && stateLocationsList?.data.filter(loc => loc.id === selectedMarker);
        // check we have selected Marker in the current list
        const isSelectedMarkerInList = currentSelectedLocations.filter(loc => loc.id === selectedLocation?.[0].id).length;
        if (isSelectedMarkerInList) {
          const updatedLocations = currentSelectedLocations.filter(loc => loc.id !== selectedLocation[0].id);
          const updatedLocationsIds = updatedLocations.map(loc => loc.id);
          setCurrentSelectedLocations(updatedLocations);
          setFieldValue(fieldName, updatedLocationsIds);
        } else {
          const updatedLocations = [...currentSelectedLocations, selectedLocation?.[0]];
          const updatedLocationsIds = updatedLocations?.map(loc => loc.id);
          setCurrentSelectedLocations(updatedLocations);
          setFieldValue(fieldName, updatedLocationsIds);
        }
      } else if (!value.latLng) { // if selected from Checkbox
        // selected location is from areaGroup
        const checkedOnRedLocations = currentAreaGroupLocations.filter(loc => loc.id === value).length > 0;
        if (checkedOnRedLocations) {
          const checkedLocation = currentAreaGroupLocations.filter(loc => loc.id !== value);
          setCurrentAreaGroupLocations(checkedLocation);
        } else {
          const lastCheckedLocation = stateLocationsList.data.filter(loc => loc.id === value);
          const isSelectedMarkerInList = currentSelectedLocations.filter(loc => loc.id === lastCheckedLocation[0].id).length > 0;
          if (isSelectedMarkerInList) {
            const updatedLocations = currentSelectedLocations.filter(loc => loc.id !== lastCheckedLocation[0].id);
            const updatedLocationsIds = updatedLocations.map(loc => loc.id);
            setCurrentSelectedLocations(updatedLocations);
            setFieldValue(fieldName, updatedLocationsIds);
          } else {
            const updatedLocations = [...currentSelectedLocations, lastCheckedLocation[0]];
            const updatedLocationsIds = updatedLocations?.map(loc => loc.id);
            setCurrentSelectedLocations(updatedLocations);
            setFieldValue(fieldName, updatedLocationsIds);
          }
        }
      }
    } 
    setTimeout(() => setFieldTouched(fieldName, true));
  };

  const handleCancel = setFieldValue => {
    setZoom(4);
    setPositionCenter(defaultPosition);
    setFieldValue('areaGroup', '');
    setFieldValue('states', []);
    setFieldValue('locations', []);
    setCurrentSelectedLocations([])
    dispatch(emptyAllLocations(resource));
  }

  const getIcon = color => {
    return {
      path: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z",
      fillColor: color,
      fillOpacity: 1,
      scale: 1.5,
      strokeColor: "white",
      strokeWeight: 1,
      scaledSize: new window.google.maps.Size(32, 32), // Scale the icon
      anchor: new window.google.maps.Point(16, 32), // Bottom center of the icon
    }
  };

  console.log('currentAreaGroupLocations', currentAreaGroupLocations)
  return (
    <>
      {loading ? (
        <div style={{ marginTop: '18% ' }}>
          <Loading />
        </div>
      ) : (
        <div>
          <Formik
            initialValues={{
              areaGroup: areaGroupDetails?.areaGroup,
              states: areaGroupDetails?.states,
              locations: areaGroupDetails?.locations,
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, { resetForm, setSubmitting, setFieldValue, errors, touched }) => {
              setButtonDisabled(true);
              setTimeout(() => {
                setSubmitting(false);
                setButtonDisabled(false);
                handleSubmitSave(values);
              }, 400);
            }}>
            {({ isSubmitting, isValid, values, setFieldValue, setFieldTouched, errors, touched, handleSubmit }) => {
              console.log(values)
              return (
                <Form>
                  <div className='area-group_formik_container'>
                    <div className='form__area-group'>
                      <div className='formik_form_area-group modal_formik_one_column'>
                        <div className='area-group__select'>
                          <div className='area-group__select__label'>Choose the Area Group</div>
                          <Select
                            options={areaGroupsOptions}
                            name='areaGroup'
                            value={{ label: getTextFromValues(values.areaGroup, areaGroupsOptions) }}
                            onChange={option => handleSetFieldValue('areaGroup', option, setFieldValue, setFieldTouched)}
                            styles={customStyles}
                            isSearchable={false}
                            components={{ DropdownIndicator: () => <DownOutlined />, IndicatorSeparator: () => null }}
                          />
                          {errors && <div className='factoring-error-msg'>{errors['areaGroup']}</div>}
                        </div>
                        {statesOptions && statesOptions?.length ? values.areaGroup && <div className='area-group__select'>
                          <div className='area-group__select__label'>Choose the Location</div>
                          <Select
                            name='states'
                            options={statesOptions}
                            isMulti
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={option => handleSetFieldValue('states', option, setFieldValue, setFieldTouched)}
                          />
                        </div> : null}
                      </div>
                      <div className='location-list__container'>
                      {currentAreaGroupLocations?.length ? currentAreaGroupLocations?.map(location => {
                        return <div style={{ display: 'flex' }}>
                              <Checkbox
                                name='locations'
                                className='location__checkbox-item'
                                onChange={e => handleSetFieldValue('locations', e.target.value, setFieldValue, setFieldTouched)}
                                value={location.id}
                                checked={currentAreaGroupLocations.filter(loc => loc.id === location.id).length > 0}
                              />
                              <div style={{ marginLeft: '8px' }}>{location.locationName}</div>
                            </div>
                          }) : null
                      }
                      {stateLocationsList?.data?.length && stateLocationsList.data.map(location => {
                        return <div style={{ display: 'flex' }}>
                              <Checkbox
                                name='locations'
                                className='location__checkbox-item'
                                onChange={e => handleSetFieldValue('locations', e.target.value, setFieldValue, setFieldTouched)}
                                value={location.id}
                                checked={[...currentSelectedLocations, ...currentAreaGroupLocations].filter(loc => loc.id === location.id).length > 0}
                              />
                              <div style={{ marginLeft: '8px' }}>{location.locationName}</div>
                            </div>
                          })
                      }
                      </div>
                    </div>

                    {isLoaded ? (
                      <GoogleMap
                          map='map'
                          mapContainerClassName='map'
                          mapContainerStyle={mapContainerStyle}
                          center={positionCenter}
                          zoom={zoom}>
                          {
                            stateLocationsList && stateLocationsList.data && stateLocationsList?.data
                              ?.filter(loc => !currentAreaGroupLocations?.map(l => l.lat).includes(loc.lat) )
                              ?.map(location => {
                                return (
                                    <Marker
                                        position={{ lat: location.lat, lng: location.lng }}
                                        onClick={clickedMarkers => handleSetFieldValue('locations', clickedMarkers, setFieldValue, setFieldTouched)}
                                        key={location.id}
                                        icon={getIcon('green')}>
                                    </Marker>
                                )
                            })
                          }
                          {
                            currentAreaGroupLocations && currentAreaGroupLocations
                            ?.filter(loc => !currentSelectedLocations.map(l => l.id).includes(loc.lat) )
                            ?.map(location => {
                                return (
                                    <Marker
                                        position={{ lat: location.lat, lng: location.lng }}
                                        onClick={clickedMarkers => handleSetFieldValue('locations', clickedMarkers, setFieldValue, setFieldTouched)}
                                        key={location.id}
                                        icon={getIcon('red')}>
                                    </Marker>
                                )
                            })
                          }
                          {
                            currentSelectedLocations && currentSelectedLocations?.map(location => {
                                return (
                                    <Marker
                                        position={{ lat: location.lat, lng: location.lng }}
                                        onClick={clickedMarkers => handleSetFieldValue('locations', clickedMarkers, setFieldValue, setFieldTouched)}
                                        key={location.id}
                                        icon={getIcon('blue')}>
                                    </Marker>
                                )
                            })
                          }
                      </GoogleMap>
                    ) : <></>}

                    <div className='area-group__buttons-container'>
                      <button
                        className='area-group__button'
                        type='submit'
                        disabled={!isValid || isSubmitting || buttonDisabled}
                        onClick={handleSubmit}>
                        Save Group
                      </button>
                      <button className='area-group__button' type='submit' onClick={() => handleCancel(setFieldValue)}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

export default withRouter(AreaGroupDetails);
