export const ERROR = 'ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const RELOAD_ON_ERROR = 'RELOAD_ON_ERROR';
export const SET_HEADER_WIDTH_AREA_GROUP_DETAILS = 'SET_HEADER_WIDTH_AREA_GROUP_DETAILS';
export const SET_DISPLAY_STATE = 'SET_DISPLAY_STATE';
export const GET_AREA_GROUP_DETAILS_LIST = 'GET_AREA_GROUP_DETAILS_LIST';
export const GET_AREA_GROUP_DETAILS_LIST_LOADING = 'GET_AREA_GROUP_DETAILS_LIST_LOADING';
export const SORT_AREA_GROUP_DETAILS = 'SORT_AREA_GROUP_DETAILS';
export const GET_AREA_GROUP_DETAILS_DETAILS_LOADING = 'GET_AREA_GROUP_DETAILS_DETAILS_LOADING';
export const GET_AREA_GROUP_DETAILS_DETAILS = 'GET_AREA_GROUP_DETAILS_DETAILS';
export const DELETE_AREA_GROUP_DETAILS_LOADING = 'DELETE_AREA_GROUP_DETAILS_LOADING';
export const ADD_AREA_GROUP_DETAILS_LOADING = 'ADD_AREA_GROUP_DETAILS_LOADING';
export const ADD_AREA_GROUP_DETAILS = 'ADD_AREA_GROUP_DETAILS';
export const UPDATE_AREA_GROUP_DETAILS_LOADING = 'UPDATE_AREA_GROUP_DETAILS_LOADING';
export const UPDATE_AREA_GROUP_DETAILS = 'UPDATE_AREA_GROUP_DETAILS';
export const GET_STATES_LIST = 'GET_STATES_LIST';
export const EMPTY_AREA_GROUP_DETAILS_DETAILS = 'EMPTY_AREA_GROUP_DETAILS_DETAILS';

export const GET_STATES_LIST_LOADING = 'GET_STATES_LIST_LOADING';
export const GET_LOCATIONS_LIST_BY_STATE_IDS = 'GET_LOCATIONS_LIST_BY_STATE_IDS';

export const getActionType = (recourse, key) => {
    return AREA_GROUP_DETAILS_BUILD[key]
};

export const AREA_GROUP_DETAILS_BUILD = {
    ERROR: 'ERROR',
    RESET_ERROR: 'RESET_ERROR',
    RELOAD_ON_ERROR: 'RELOAD_ON_ERROR',
    SET_HEADER_WIDTH_AREA_GROUP_DETAILS: 'SET_HEADER_WIDTH_AREA_GROUP_DETAILS',
    GET_AREA_GROUP_DETAILS_LIST: 'GET_AREA_GROUP_DETAILS_LIST',
    GET_AREA_GROUP_DETAILS_LIST_LOADING: 'GET_AREA_GROUP_DETAILS_LIST_LOADING',
    SORT_AREA_GROUP_DETAILS: 'SORT_AREA_GROUP_DETAILS',
    GET_AREA_GROUP_DETAILS_DETAILS_LOADING: 'GET_AREA_GROUP_DETAILS_DETAILS_LOADING',
    GET_AREA_GROUP_DETAILS_DETAILS: 'GET_AREA_GROUP_DETAILS_DETAILS',
    DELETE_AREA_GROUP_DETAILS_LOADING: 'DELETE_AREA_GROUP_DETAILS_LOADING',
    ADD_AREA_GROUP_DETAILS_LOADING: 'ADD_AREA_GROUP_DETAILS_LOADING',
    ADD_AREA_GROUP_DETAILS: 'ADD_AREA_GROUP_DETAILS',
    GET_STATES_LIST: 'GET_STATES_LIST',
    EMPTY_AREA_GROUP_DETAILS_DETAILS: 'EMPTY_AREA_GROUP_DETAILS_DETAILS',
    UPDATE_AREA_GROUP_DETAILS_LOADING: 'UPDATE_AREA_GROUP_DETAILS_LOADING',
    UPDATE_AREA_GROUP_DETAILS: 'UPDATE_AREA_GROUP_DETAILS',

    GET_STATES_LIST_LOADING: 'GET_STATES_LIST_LOADING',
    GET_LOCATIONS_LIST_BY_STATE_IDS: 'GET_LOCATIONS_LIST_BY_STATE_IDS',
};
